import cx from 'classnames';
import React from 'react';

import { AddReviewForm } from '../components/add-review-form-modal';
import { CategoriesFilterPanel } from '../components/blog/categories-filter-panel';
import { Breadcrumbs, BreadcrumbsItem } from '../components/breadcrumbs';
import { Container } from '../components/container';
import { Review } from '../components/review';

const ReviewsPage = () => (
  <Container>
    <Breadcrumbs>
      <BreadcrumbsItem link="/">Heim</BreadcrumbsItem>
      <BreadcrumbsItem link="/reviews">Bewertungen</BreadcrumbsItem>
    </Breadcrumbs>

    <h3 className="fancyUnderlineText sectionTitleNew text-center">
      Alle <span>Bewertungen</span>
    </h3>
    <AddReviewForm />
    {/* <CategoriesFilterPanel /> */}
    <Review />
  </Container>
)

export default ReviewsPage
