import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button2 } from '../button2';
import { AddReviewFormLayoutForm } from '../forms/add-review-form';
import { useModal } from '../modal2';
import styles from '../modal2/modal.module.css';

const AddReviewModalForm = ({ formName }) => (
  <>
    <h3 className={cx("text-center", styles.modalHeaderTitle)}>
      Váš názor nás  <b>zajímá</b>
    </h3>
    <AddReviewFormLayoutForm form_name={formName} btnText="Odeslat" />
  </>
)

export const AddReviewForm = ({ formName }) => {
  const { show, RenderModal } = useModal()
  const { t } = useTranslation()

  return (
    <>
      <RenderModal className="modalForm">
        <AddReviewModalForm formName={formName} />
      </RenderModal>
      {/* <div className="text-center" style={{marginBottom: "40px"}}>
      <Button2 color="primary" handleClick={show}>
        {t("forms.reviewFormOpenModalBtn")}
      </Button2>
      </div> */}
    </>
  )
}
